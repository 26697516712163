// Import our Bootstrap extensions
// Import all plugins
import * as bootstrap from 'bootstrap';

import Alpine from 'alpinejs';
import persist from '@alpinejs/persist'
import Prism from 'prismjs';
import './vendor';

import { I18n } from "i18n-js";
import en from "../../../locales/en.json";
import es from "../../../locales/es.json";

const i18n = new I18n({
    ...en,
    ...es,
});

window.i18n = i18n;
window.Alpine = Alpine;
Alpine.plugin(persist);

document.addEventListener('alpine:init', () => {
    Alpine.data('boxlangData', function () {
        return {
            isDark: this.$persist(true).as('boxlang-darkmode'),
            isOpenMenu: false,
            locale: this.$persist((i18n.locale.length ? i18n.locale : 'en')).as('boxlang-locale'),
            langSelected: {},
            scrolled: false,

            init() {
                this.getLangSelected()
            },

            languages: [
                {
                    locale: "EN",
                    icon: "/__media/boxlang:flags/en.svg"
                },
                {
                    locale: "ES",
                    icon: "/__media/boxlang:flags/es.svg"
                }
            ],

            getLangSelected() {
                var lang = this.languages.filter(item => { return item.locale == this.locale.toUpperCase() });
                this.langSelected = Object.keys(lang).length ? lang[0] : this.languages[0];
            },

            getI18nValue(name) {
                return i18n.t(name);
            },

            updateLanguage(language) {
                this.locale = language;
                i18n.locale = language;

                this.getLangSelected()
            },

            async getContributors() {
                let response = await fetch(window.location.origin + '/api/contributors');
                let result = await response.json();
                return result.length ? result : [];
            },
            
            async getNews() {
                let response = await fetch(window.location.origin + '/api/news');
                let result = await response.json();
                return result.length ? result : [];
            },

            getCustomFieldValue(arr, name) {
                return arr.reduce((acc, cur) => {
                    if (cur.key == name) {
                        acc = cur.value;
                    }
                    return acc;
                }, "");
            },

            get isHomePage(){
                let currentURL = window.location.pathname.replace( "/", "" );
                return currentURL.length ? false : true
            }
        }
    })
})

Alpine.start();


window.onload = () => {
    // Update the Bitrix form fields to add them the aria-label attribute
    let listFormFields = document.getElementsByClassName( "b24-form-control-label" );
    if( listFormFields ) {
        for( var i = 0; i < listFormFields.length; i++ ){
            let description = listFormFields[i].textContent.replace(/\r?\n|\r/g, "").replace( "*", "" ).trim();
            listFormFields[i].setAttribute( 'aria-label', description );
        }
    }
}
